<template>
    <div>
        <modal name="modal-display-grossiste" height="auto" width="80%" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-2">Grossiste</h2>
                <fournisseur-view
                :edit="false"
                :id="selectedLine.id"
                />
            </div>
        </modal>

        <modal name="modal-new-enseigne" width="80%" height="auto" :scrollable="true">
            <div class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-2 border-b mb-4 pb-4">Changer l'enseigne</h2>
                <div class="flex gap-4 border-b mb-4 pb-4">
                    <button @click="newEnseigneMenu = 'switch'" class="px-4 py-2 rounded text-sm" :class="newEnseigneMenu == 'switch' ? 'bg-blue-400 text-white' : 'bg-slate-200 hover:bg-slate-300 duration-200'">Enseigne existante</button>
                    <button @click="newEnseigneMenu = 'create'" class="px-4 py-2 rounded text-sm" :class="newEnseigneMenu == 'create' ? 'bg-blue-400 text-white' : 'bg-slate-200 hover:bg-slate-300 duration-200'">Créer une enseigne</button>
                </div>
                <div v-if="newEnseigneMenu == 'switch'" class="h-[200px]">
                    <input-rule
                    v-model="newEnseigne"
                    type="api-select"
                    :options="{
                        api: {action: 'centrale.search', apiName: 'api2', options: {limit: 500, orderBy: 'centrale_ha'}},
                        label: 'centrale_ha',
                    }"
                    :label-inline="false"
                    label="Rechercher une enseigne existante"
                    id="inp-centrale-ha"
                    />
                    <template v-if="newEnseigne">
                        <button @click="changeEnseigne" :disabled="loaderChangeCentrale" class="px-4 py-2 bg-green-500 text-white text-xs font-light rounded mt-2">
                            <template v-if="loaderChangeCentrale">
                                <spinner :size="10" />
                            </template>
                            <template v-else>
                                Confirmer le changement
                            </template>
                        </button>
                    </template>
                </div>
                <div v-if="newEnseigneMenu == 'create'">
                    <centrale-view
                    @update="(data) => {newEnseigne = data; changeEnseigne()}"
                    :edit="true"
                    :id="null"
                    :data-src="{
                        centrale_ha: null,
                        actif: 1,
                        ecommerce: null,
                        adresse1: null,
                        ville: null,
                        cp: null,
                        code_pays: null,
                        is_grossiste: null,
                    }"
                    />
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-close-claim" height="auto" width="1000px" :scrollable="true">
            <div class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-2">Refuser la demande</h2>
                <div class="grid grid-cols-5 gap-4">
                    <div class="col-span-2">
                        <saved-complement-msgs type="shop" from="refus" @select="(data) => motifRejet = data" />
                    </div>
                    <div class="col-span-3">
                        <div>Pourquoi refusez-vous la demande ?</div>
                        <input-rule v-model="motifRejet" class="mt-2" type="richtext" :label-inline="false" id="inp-rejet" />
                        <div class="flex items-center gap-2 mt-2">
                            <button :disabled="loaderLiaison" @click="$modal.hide('modal-confirm-close-claim')" class="bg-slate-200 px-4 py-2 rounded">Annuler</button>
                            <button :disabled="loaderLiaison" @click="closeClaim" class="bg-red-200 px-4 py-2 rounded">Refuser</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-complement" height="auto" width="1000px" :scrollable="true">
            <div v-if="displayComplement" class="p-10" style="z-index: 999 !important;">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold text-xl">
                        Demande de complément
                    </h2>
                    <button @click="$modal.hide('modal-confirm-complement'); selectedLine = null">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="grid grid-cols-5 gap-4">
                    <div class="col-span-2">
                        <saved-complement-msgs type="shop" from="complement" />
                    </div>
                    <div class="col-span-3">
                        <tchat-complement :claim-id="claimId" :admin="true" />
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-delete-claim" height="auto" :scrollable="true">
            <div class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-2">Supprimer la demande</h2>
                <p class="font-light p-2 rounded bg-slate-100 my-4">Cette action n'enverra aucun email à l'utilisateur, la demande sera supprimée du système</p>
                <div class="flex items-center gap-2 mt-2">
                    <button @click="$modal.hide('modal-confirm-delete-claim')" class="bg-slate-200 px-4 py-2 rounded">Annuler</button>
                    <button @click="deleteClaim" class="bg-red-200 px-4 py-2 rounded">Supprimer</button>
                </div>
            </div>
        </modal>

        <modal name="modal-send-catalog" height="auto" :scrollable="true">
            <div class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-2">Envoyer le catalogue centrale achat</h2>
                <input-rule type="text" v-model="addressSendCatalog" label="Adresse email" :label-inline="false" id="inp-mail-catalog" />
                <button :disabled="loadingSendCatalog" @click="sendCatalog" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-white text-sm mt-2">
                    <spinner v-if="loadingSendCatalog" :size="10" />
                    <template v-if="!loadingSendCatalog">
                        <i class="fas fa-check mr-1"></i> Envoyer
                    </template>
                </button>
            </div>
        </modal>

        <!-- Page content -->
        <div v-if="displayPage" class="bg-white rounded-xl border shadow-sm">
            <div class="py-4" v-if="loaderGetData">
                <div class="flex justify-center">
                    <spinner :size="12" />
                </div>
                <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
            </div>
            <div class="p-10" v-if="claimId && !loaderGetData">
                <div class="mb-5 grid md:flex md:grid-cols-2 gap-2 justify-end">
                    <template v-if="!loadingCloseClaim">
                        <button @click="addressSendCatalog = claim.magasin.email; $modal.show('modal-send-catalog')" class="bg-slate-100 hover:bg-slate-200 duration-200 text-slate-900 px-4 py-2 rounded text-sm font-light"><i class="fas fa-book mr-1"></i> Envoyer le catalogue</button>
                        <button @click="displayComplement = true; $modal.show('modal-confirm-complement')" class="bg-purple-100 hover:bg-purple-200 duration-200 text-purple-900 px-4 py-2 rounded text-sm font-light"><i class="fas fa-message mr-1"></i> Demande de complément</button>
                        <button v-if="claimFlag == 0" @click="$modal.show('modal-confirm-delete-claim')" class="bg-amber-100 hover:bg-amber-200 duration-200 text-red-900 px-4 py-2 rounded text-sm font-light"><i class="fas fa-trash mr-1"></i> Supprimer la demande</button>
                        <button v-if="claimFlag == 0" @click="$modal.show('modal-confirm-close-claim')" class="bg-red-100 hover:bg-red-200 duration-200 text-red-900 px-4 py-2 rounded text-sm font-light"><i class="fas fa-times mr-1"></i> Refuser la demande</button>
                        <button v-if="claimFlag == 0" @click="endClaim" class="bg-green-100 hover:bg-green-200 duration-200 text-green-900 px-4 py-2 rounded text-sm font-light"><i class="fas fa-check mr-1"></i> Clôturer la demande</button>
                    </template>
                    <spinner v-else :size="10" />
                </div>
                <div class="bg-green-100 p-4 rounded text-green-800 font-bold mb-5" v-if="claimFlag == 1">
                    Demande clôturée
                    <div v-if="botValidation" class="text-xs font-light">Demande validée automatiquement</div>
                    <div v-if="acceptedBy" class="text-xs font-light">Clôturée par {{ acceptedBy.email }} le {{ endDate | momentDateTime }}</div>
                </div>
                <div class="bg-red-100 p-4 rounded text-red-800 font-bold mb-5" v-if="claimFlag == 2">
                    <div>Demande refusée</div>
                    <div class="text-sm font-light"><div v-html="motifRejet"></div></div>
                    <div v-if="refusedBy" class="text-xs font-light mt-2 pt-2 border-t border-red-700">Refusée par {{ refusedBy.email }} le {{ endDate | momentDateTime }}</div>
                </div>
                <div class="bg-red-100 p-4 rounded text-red-800 font-bold mb-5" v-if="claimFlag == 3">
                    <div>Demande supprimée</div>
                    <div v-if="refusedBy" class="text-xs font-light">Supprimée par {{ refusedBy.email }} le {{ endDate | momentDateTime }}</div>
                </div>
                <div class="bg-orange-100 p-4 rounded text-orange-800 text-sm mb-5" v-if="errors && errors.length > 0 && claimFlag == 0">
                    <div class="mb-2 font-semibold">Points à vérifier : </div>
                    <ul class="ml-4 list-disc font-light">
                        <li v-for="(error, errorIndex) in errors" :key="'error-' + errorIndex">{{ error }}</li>
                        <li v-if="!claim.enseigne.id">Société: Vous devez créer la société</li>
                        <li v-if="!claim.account.id">Utilisateur: Vous devez créer l'utilisateur</li>
                    </ul>
                </div>
                <!-- Navbar -->
                <ul class="grid md:grid-cols-6 text-xs text-center divide-x divide-gray-200 rounded-lg border">
                    <li class="w-full">
                        <button @click="selectedMenu = 'enseigne'" :class="selectedMenuStyle('enseigne')" class="rounded-l-lg ">
                            <i v-if="!claim.enseigne.id" class="fa-solid fa-triangle-exclamation mr-1"></i>
                            <i v-else class="fa-solid fa-circle-check mr-1"></i>
                            Enseigne
                        </button>
                    </li>
                    <li class="w-full">
                        <button @click="selectedMenu = 'magasin'" :class="selectedMenuStyle('magasin')">
                            <i v-if="!claim.magasin.id" class="fa-solid fa-triangle-exclamation mr-1"></i>
                            <i v-else class="fa-solid fa-circle-check mr-1"></i>
                            Magasin
                        </button>
                    </li>
                    <li class="w-full">
                        <button @click="selectedMenu = 'account'" :class="selectedMenuStyle('account')">
                            <i v-if="!claim.account.id" class="fa-solid fa-triangle-exclamation mr-1"></i>
                            <i v-else class="fa-solid fa-circle-check mr-1"></i>
                            Utilisateur
                        </button>
                    </li>
                    <li class="w-full">
                        <button @click="selectedMenu = 'liaisons'" :class="selectedMenuStyle('liaisons')">
                            <i v-if="mustValidLiaison" class="fa-solid fa-triangle-exclamation mr-1"></i>
                            <i v-else class="fa-solid fa-circle-check mr-1"></i>
                            Liaisons
                        </button>
                    </li>
                    <li class="w-full">
                        <button @click="selectedMenu = 'informations'" :class="selectedMenuStyle('informations')">
                            Commercial
                        </button>
                    </li>
                    <li class="w-full">
                        <button @click="selectedMenu = 'tools'" :class="selectedMenuStyle('tools')" class="rounded-r-lg">
                            Outils
                        </button>
                    </li>
                </ul>

                <!-- Enseigne -->
                <div v-if="selectedMenu == 'enseigne'" class="mt-4">
                    <div v-if="!claim.enseigne.id" class="p-4 mb-4 font-bold text-white rounded-lg bg-slate-800 flex items-center gap-2" role="alert">
                        <i class="fas fa-triangle-exclamation mr-1 text-2xl"></i>
                        <div>
                            Nouvelle enseigne
                            <div class="text-sm font-light">Cette enseigne n'est pas enregistrée sur Districloud</div>
                        </div>
                    </div>
                    <button class="px-6 py-2 bg-blue-100 hover:bg-blue-200 duration-200 cursor-pointer rounded mb-4 text-sm font-light" @click="$modal.show('modal-new-enseigne')">
                        <i class="fa-solid fa-repeat mr-1"></i> Changer l'enseigne
                    </button>
                    <centrale-view
                    @update="centraleUpdate"
                    :edit="true"
                    :id="claim.enseigne.id"
                    :not-edit-name="claim.enseigne.id ? true : false"
                    :data-src="!claim.enseigne.exist ? {
                        centrale_ha: claim.enseigne.nom,
                        actif: 1,
                        ecommerce: claim.enseigne.ecommerce,
                        adresse1: claim.magasin.adresse.adresse1,
                        ville: claim.magasin.adresse.ville,
                        cp: claim.magasin.adresse.cp,
                        code_pays: claim.pays.code,
                        is_grossiste: claim.enseigne.grossiste
                    } : null"
                    />
                </div>

                <!-- Magasin -->
                <div v-if="selectedMenu == 'magasin'" class="mt-4">
                    <div v-if="!claim.enseigne.id" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                        <i class="fas fa-triangle-exclamation mr-1"></i> La création de l'enseigne est obligatoire pour créer le magasin
                    </div>
                    <div v-if="claim.enseigne.id && !claim.magasin.id" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                        <i class="fas fa-triangle-exclamation mr-1"></i> Vous devez créer le magasin
                    </div>
                    <div v-if="claim.magasin.secteur" class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50" role="alert">
                        <h2 class="font-bold">Informations</h2>
                        <ul class="list-disc ml-4">
                            <li v-if="!claim.enseigne.id">SIRET: {{ claim.magasin.siret }}</li>
                            <li v-if="!claim.enseigne.id">TVA: {{ claim.magasin.tva }}</li>
                            <li v-if="!claim.enseigne.id">Nom: {{ claim.magasin.nom }}</li>
                            <li v-if="!claim.enseigne.id">Adresse: {{ claim.magasin.adresse.adresse1 + ' ' + claim.magasin.adresse.cp + ' ' + claim.magasin.adresse.ville + ' ' + claim.magasin.adresse.pays}}</li>
                            <li v-if="!claim.enseigne.id">E-commerce: {{ claim.magasin.ecommerce ? 'Oui' : 'Non' }}</li>
                            <li v-if="!claim.enseigne.id">Magasin indépendant: {{ claim.magasin.independant ? 'Oui' : 'Non' }}</li>
                            <li>Activité: {{ claim.magasin.secteur.label }}</li>
                        </ul>
                    </div>
                    <magasin-view
                    v-if="claim.enseigne.id"
                    :edit="true"
                    :id="claim.magasin.id"
                    @update="magasinUpdate"
                    :data-src="!claim.magasin.exist ? {
                        actif: 1,
                        independant: claim.magasin.independant,
                        magasin: claim.magasin.nom,
                        adresse1: claim.magasin.adresse.adresse1,
                        adresse2: claim.magasin.adresse.adresse2,
                        cp: claim.magasin.adresse.cp,
                        ville: claim.magasin.adresse.ville,
                        pays: claim.pays.code,
                        numintra: claim.magasin.tva,
                        siret_mag: claim.magasin.siret,
                        id_centrale_ha: claim.enseigne.id,
                        telsav: claim.account.tel,
                        mailant: claim.account.email,
                        web: claim.magasin.ecommerce,
                    } : null"
                    />
                </div>

                <!-- Account -->
                <div v-if="selectedMenu == 'account'" class="mt-4">
                    <div v-if="!claim.magasin.id" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                        <i class="fas fa-triangle-exclamation mr-1"></i> La création du magasin est obligatoire pour créer un compte
                    </div>
                    <div v-if="!claim.account.id && claim.magasin.id" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                        <i class="fas fa-triangle-exclamation mr-1"></i> Vous devez créer l'utilisateur
                    </div>
                    <div v-if="!claim.enseigne.id" class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50" role="alert">
                        <h2 class="font-bold">Informations</h2>
                        <ul class="list-disc ml-4">
                            <li>Nom: {{ claim.account.nom }}</li>
                            <li>Prénom: {{ claim.account.prenom }}</li>
                            <li>Téléphone: {{ claim.account.tel }}</li>
                            <li>Email: {{ claim.account.email }}</li>
                        </ul>
                    </div>
                    <account-view
                    v-if="claim.magasin.id"
                    :edit="true"
                    :id="claim.account.id"
                    @update="accountUpdate"
                    :data-src="!claim.account.id ? {
                        id_entite: claim.magasin.id,
                        type_entite: 3,
                        email: claim.account.email,
                        contact_mail: claim.account.email,
                        contact_mobile: claim.account.tel,
                        user_name: claim.account.nom,
                        prenom_user: claim.account.prenom,
                        poste: claim.account.poste,
                        id_parent: claim.enseigne.id,
                        user_lang: (claim.lang || 'FR').toUpperCase()
                    } : null"
                    />
                </div>

                <!-- Liaison -->
                <div v-if="selectedMenu == 'liaisons'" class="mt-4">
                    <div v-if="!claim.enseigne.id" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                        <i class="fas fa-triangle-exclamation mr-1"></i> La création de l'enseigne est obligatoire pour gérer les liaisons
                    </div>
                    <div v-if="!claim.enseigne.id" class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50" role="alert">
                        <h2 class="font-bold">Informations</h2>
                        <ul class="list-disc ml-4">
                            <li v-for="(liaisonInfo, liaisonInfoIndex) in claim.liaisons" :key="'liaison-info-' + liaisonInfoIndex">
                                Liaison avec {{ liaisonInfo.nom }}
                            </li>
                        </ul>
                    </div>
                    <div class="bg-slate-100 rounded p-4 text-xs font-light mb-2" v-if="!claim.liaisons || claim.liaisons.length <= 0">
                        <i class="fas fa-check mr-1"></i> Aucune liaison à réaliser
                    </div>
                    <div v-if="claim.enseigne.id">
                        <centrale-liaisons
                        :id="claim.enseigne.id"
                        :nom="claim.enseigne.nom"
                        :validations="(claim.liaisons[0] && !claim.liaisons[0].found && !claim.liaisons[0].liaison_id ? [claim.liaisons[0]] : [])"
                        @update="updateLiaison"
                        @cancel="cancelLiaison"
                        />
                    </div>
                </div>

                <!-- informations -->
                <div v-show="selectedMenu == 'informations'" class="mt-4">
                    <!-- <div class="mb-4">
                        <input-rule v-model="selectTypeDolibarr" type="select" :options="{values: [{label: 'Centrale', value: 'centrale'}, {label: 'Magasin', value: 'magasin'}], key: 'value', label: 'label'}" label="Type" />
                    </div> -->
                    <div class="flex items-center gap-2 mb-2">
                        <button @click="selectTypeDolibarr = 'centrale'" class="px-4 py-2 rounded text-xs" :class="selectTypeDolibarr == 'centrale' ? 'bg-blue-500 text-white' : 'bg-slate-200 hover:bg-slate-300 duration-200'">Enseigne</button>
                        <button @click="selectTypeDolibarr = 'magasin'" class="px-4 py-2 rounded text-xs" :class="selectTypeDolibarr == 'magasin' ? 'bg-blue-500 text-white' : 'bg-slate-200 hover:bg-slate-300 duration-200'">Magasin</button>
                    </div>
                    <add-dolibarr-thier
                    v-if="refreshDolibarr"
                    :claim-id="dataSrc.id"
                    :dolibarr-id="dataSrc.dolibarr_id"
                    :societe="selectTypeDolibarr == 'centrale' ? centraleDolibarr.centrale_ha : claim.enseigne.nom"
                    :adresse="selectTypeDolibarr == 'centrale' ? centraleDolibarr.adresse1 : claim.magasin.adresse.adresse1"
                    :cp="selectTypeDolibarr == 'centrale' ? centraleDolibarr.cp : claim.magasin.adresse.cp"
                    :ville="selectTypeDolibarr == 'centrale' ? centraleDolibarr.ville : claim.magasin.adresse.ville"
                    :pays="selectTypeDolibarr == 'centrale' ? centraleDolibarr.pays : claim.magasin.adresse.pays"
                    :tel="selectTypeDolibarr == 'centrale' ? centraleDolibarr.tel : claim.account.tel"
                    :nom="claim.account.nom"
                    :prenom="claim.account.prenom"
                    :poste="claim.account.poste"
                    :mail="claim.account.email"
                    />
                </div>

                <!-- tools -->
                <div v-if="selectedMenu == 'tools'" class="mt-4">
                    <div class="border p-4 rounded">
                        <convert-claim-to-supplier :claim-id="dataSrc.id" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import MagasinView from '../../../Magasin/MagasinView.vue'
import AccountView from '../../../Magasin/AccountView.vue'
import CentraleView from '../../../Centrale/CentraleView.vue'
import FournisseurView from '../../../Fournisseur/FournisseurView.vue'
import AddDolibarrThier from './AddDolibarrThiers.vue'
import ConvertClaimToSupplier from './ConvertClaimToSupplier.vue'
import TchatComplement from '../TchatComplement.vue'
import SavedComplementMsgs from '../SavedComplementMsgs.vue'

import CentraleLiaisons from '../../../Centrale/CentraleLiaisons.vue'

export default {
    props: {
        dataSrc: {required: true}
    },

    components: {
        MagasinView,
        CentraleView,
        AccountView,
        FournisseurView,
        CentraleLiaisons,
        AddDolibarrThier,
        ConvertClaimToSupplier,
        TchatComplement,
        SavedComplementMsgs
    },

    watch: {
        dataSrc: function () {
            this.initClaim()
        },

        selectedMenu: async function(value) {
            if (value == 'informations') {
                let response = await this.$request.post('centrale.view', {
                    line_id: this.claim.enseigne.id
                })
                this.centraleDolibarr = response.data.posts.post
            }
            this.refreshDolibarr = false
            this.$nextTick(() => {
                this.refreshDolibarr = true
            })
        },

        selectTypeDolibarr: function() {
            this.refreshDolibarr = false
            this.$nextTick(() => {
                this.refreshDolibarr = true
            })
        }
    },

    data() {
        return {
            displayPage: false,

            claimsList: [],
            claim: null,
            claimId: null,
            claimFlag: null,
            selectedClaimType: 0,
            acceptedBy: null,
            refusedBy: null,
            endDate: null,
            botValidation: false,

            errors: [],

            informations: '',
            noteTimer: null,
            motifRejet: null,
            newEnseigne: null,
            newEnseigneMenu: 'switch',

            displayComplement: false,

            mustValidLiaison: false,

            selectedMenu: 'enseigne',
            selectedLine: null,

            loaderGetData: false,
            loaderChangeCentrale: false,
            loadingCloseClaim: false,

            selectTypeDolibarr: 'centrale',
            centraleDolibarr: {},
            refreshDolibarr: true,

            addressSendCatalog: null,
            loadingSendCatalog: false,
        }
    },

    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },

    methods: {
        initClaim() {
            this.displayPage = false
            this.claim = this.dataSrc.claim
            this.informations = this.dataSrc.informations
            this.claimId = this.dataSrc.id
            this.claimFlag = this.dataSrc.flag
            this.motifRejet = this.dataSrc.motif_rejet
            this.errors = this.dataSrc.errors
            this.acceptedBy = this.dataSrc.accepted_by
            this.refusedBy = this.dataSrc.refused_by
            this.endDate = this.dataSrc.end_date
            this.botValidation = this.dataSrc.bot_validation
            this.selectedMenu = 'enseigne'
            // this.selectNextMenu()
            if (this.claim.liaisons) {
                this.mustValidLiaison = this.claim.liaisons.find((item) => item.nom && !item.liaison_id && !item.cancelled) ? 1 : 0
                this.claim.liaisons.map((l) => ({...l, code_compta_ext: (l.code_compta_ext || null)}))
            }
            this.$nextTick(() => {
                this.displayPage = true
            })
        },
    
        async editClaim() {
            let endObj = {
                line_id: this.claimId,
                json: JSON.stringify(this.claim),
            }
            if (this.informations) {
                endObj.informations = this.informations
            }
            let response = await this.$request.post('inscription.edit-claim', endObj)
            this.errors = JSON.parse(response.data.posts.post.errors)
            if (response.data.posts.post.flag != this.claimFlag) {
                this.$parent.$parent.getClaim(this.claimsList[0].id)
            }
        },

        selectNextMenu() {
            if (this.mustValidLiaison) {
                this.selectedMenu = 'liaisons'
            }
            if (!this.claim.account.id) {
                this.selectedMenu = 'account'
            }
            if (!this.claim.magasin?.exist) {
                this.selectedMenu = 'magasin'
            }
            if (!this.claim.enseigne?.exist) {
                this.selectedMenu = 'enseigne'
            }
        },

        async centraleUpdate(data) {
            this.claim.enseigne.exist = true
            this.claim.enseigne.id = data.id
            this.claim.enseigne.ecommerce = data.ecommerce
            this.claim.enseigne.nom = data.centrale_ha
            this.$nextTick(() => {
                this.editClaim()
                this.selectNextMenu()
            })
        },
        async magasinUpdate(data) {
            this.claim.magasin.exist = true
            this.claim.magasin.id = data.id
            this.claim.magasin.ecommerce = data.web
            this.claim.magasin.has_centrale = !data.independant
            this.claim.magasin.nom = data.magasin
            this.claim.magasin.siret = data.siret_mag
            this.claim.magasin.tva = data.numintra
            this.claim.magasin.adresse.adresse1 = data.adresse1
            this.claim.magasin.adresse.adresse2 = data.adresse2
            this.claim.magasin.adresse.cp = data.cp
            this.claim.magasin.adresse.latitude = data.lat
            this.claim.magasin.adresse.longitude = data.lng
            this.claim.magasin.adresse.pays = data.pays
            this.claim.magasin.adresse.ville = data.ville
            this.$nextTick(() => {
                this.editClaim()
                this.selectNextMenu()
            })
        },
        async accountUpdate(data) {
            this.claim.account.exist = true
            this.claim.account.id = data.id
            this.claim.account.email = data.email
            this.claim.account.tel = data.contact_mobile
            this.claim.account.poste = data.poste
            this.$nextTick(() => {
                this.editClaim()
                this.selectNextMenu()
            })
        },

        selectedMenuStyle(type) {
            let style = 'inline-block w-full p-3 focus:outline-none'

            if (this.selectedMenu == type) {
                style += '  font-medium'
                if ((this.selectedMenu == 'account' && !this.claim.account.id) || (this.selectedMenu == 'magasin' && !this.claim.magasin.id) || (this.selectedMenu == 'enseigne' && !this.claim.enseigne.id) || (this.selectedMenu == 'liaisons' && this.mustValidLiaison)) {
                    style += ' bg-orange-400 text-white'
                } else {
                    style += ' bg-slate-200'
                }
            } else {
                style += ' font-light hover:bg-gray-50'
                if ((type == 'account' && !this.claim.account.id) || (type == 'magasin' && !this.claim.magasin.id) || (type == 'enseigne' && !this.claim.enseigne.id) || (type == 'liaisons' && this.mustValidLiaison)) {
                    style += ' text-orange-600'
                } else {
                    style += ' text-slate-900'
                }
            }
            return style
        },

        async endClaim() {
            this.loadingCloseClaim = true
            let response = await this.$request.post('inscription.end-claim', {
                line_id: this.claimId
            })
            this.loadingCloseClaim = false
            if (response.data.posts.post) {
                this.claimFlag = 1
                this.$eventHub.$emit('refresh-claim-list')
            }
        },

        async closeClaim() {
            if (!this.$validation.check([
                {value: this.motifRejet, rules: 'required', id: 'inp-rejet'},
            ])) {return}

            await this.$request.post('inscription.close', {
                line_id: this.claimId,
                motif: this.motifRejet
            })
            this.claimFlag = 2
            this.$eventHub.$emit('refresh-claim-list')
            this.$modal.hide('modal-confirm-close-claim')
            this.loaderLiaison = false
        },

        async deleteClaim() {
            await this.$request.post('inscription.delete', {
                line_id: this.claimId,
            })
            this.claimFlag = 3
            this.$eventHub.$emit('refresh-claim-list')
            this.$modal.hide('modal-confirm-delete-claim')
        },

        async changeEnseigne() {
            this.loaderChangeCentrale = true
            if (this.claim.magasin.id) {
                await this.$request.post('magasin.edit-add', {line_id: this.claim.magasin.id, id_centrale_ha: this.newEnseigne.id})
            }
            this.claim.enseigne.id = this.newEnseigne.id
            this.claim.enseigne.nom = this.newEnseigne.centrale_ha
            await this.editClaim()
            var url = new URL(window.location.href);
            var urlId = url.searchParams.get("id");
            let response = await this.$request.post('inscription.get', {
                line_id: urlId
            })
            this.$parent.$parent.getClaim(response.data.posts.post);
            this.newEnseigne = null
            this.$modal.hide('modal-new-enseigne')
            this.loaderChangeCentrale = false
        },

        updateLiaison(data) {
            this.claim.liaisons.find((item) => item.id == this.claim.liaisons[0].id).liaison_id = data
            this.editClaim()
            this.initClaim()
        },

        async cancelLiaison(liaison) {
            this.loaderLiaison = true
            this.claim.liaisons.find((item) => item.id == liaison.id).cancelled = true
            this.mustValidLiaison = 0
            this.editClaim()
            this.loaderLiaison = false
        },

        async sendCatalog() {
            if (!this.$validation.check([
                {value: this.addressSendCatalog, rules: 'required|email', id: 'inp-mail-catalog'},
            ])) {return}

            this.loadingSendCatalog = true
            let response = await this.$request.send('inscription.send-catalog', {
                email: this.addressSendCatalog,
                type: 'shop'
            })
            this.loadingSendCatalog = false
            if (response == 1) {
                this.$modal.hide('modal-send-catalog')
                this.$toast.success('Catalogue envoyé')
            }
        }
    },

    mounted () {
        this.initClaim()
    },
}
</script>

<style lang="scss" scoped>

</style>
