<template>
    <div>
        <modal name="modal-confirm-close-claim" height="auto" width="1000px" :scrollable="true">
            <div class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-2">Refuser la demande</h2>
                <div class="grid grid-cols-5 gap-4">
                    <div class="col-span-2">
                        <saved-complement-msgs type="reparateur" from="refus" @select="(data) => motifRejet = data" />
                    </div>
                    <div class="col-span-3">
                        <div>Pourquoi refusez-vous la demande ?</div>
                        <input-rule v-model="motifRejet" class="mt-2" type="richtext" :label-inline="false" id="inp-rejet" />
                        <div class="flex items-center gap-2 mt-2">
                            <button :disabled="loaderLiaison" @click="$modal.hide('modal-confirm-close-claim')" class="bg-slate-200 px-4 py-2 rounded">Annuler</button>
                            <button :disabled="loaderLiaison" @click="closeClaim" class="bg-red-200 px-4 py-2 rounded">Refuser</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-complement" height="auto" width="1000px" :scrollable="true">
            <div v-if="displayComplement" class="p-10" style="z-index: 999 !important;">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold text-xl">
                        Demande de complément
                    </h2>
                    <button @click="$modal.hide('modal-confirm-complement'); selectedLine = null">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="grid grid-cols-5 gap-4">
                    <div class="col-span-2">
                        <saved-complement-msgs type="reparateur" from="complement" />
                    </div>
                    <div class="col-span-3">
                        <tchat-complement :claim-id="claimId" :admin="true" />
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-delete-claim" height="auto" :scrollable="true">
            <div class="p-10" style="z-index: 999 !important;">
                <h2 class="text-xl font-bold mb-2">Supprimer la demande</h2>
                <p class="font-light p-2 rounded bg-slate-100 my-4">Cette action n'enverra aucun email à l'utilisateur, la demande sera supprimée du système</p>
                <div class="flex items-center gap-2 mt-2">
                    <button @click="$modal.hide('modal-confirm-delete-claim')" class="bg-slate-200 px-4 py-2 rounded">Annuler</button>
                    <button @click="deleteClaim" class="bg-red-200 px-4 py-2 rounded">Supprimer</button>
                </div>
            </div>
        </modal>

        <!-- Page content -->
        <div v-if="displayPage" class="bg-white rounded-xl border shadow-sm">
            <div class="py-4" v-if="loaderGetData">
                <div class="flex justify-center">
                    <spinner :size="12" />
                </div>
                <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
            </div>
            <div class="p-10" v-if="claimId && !loaderGetData">
                <div class="mb-5 flex gap-2 justify-end">
                    <template v-if="!loadingCloseClaim">
                        <button @click="displayComplement = true; $modal.show('modal-confirm-complement')" class="bg-purple-100 hover:bg-purple-200 duration-200 text-purple-900 px-4 py-2 rounded text-sm font-light"><i class="fas fa-message mr-1"></i> Demande de complément</button>
                        <button v-if="claimFlag == 0" @click="$modal.show('modal-confirm-delete-claim')" class="bg-amber-100 hover:bg-amber-200 duration-200 text-red-900 px-4 py-2 rounded text-sm font-light"><i class="fas fa-trash mr-1"></i> Supprimer la demande</button>
                        <button v-if="claimFlag == 0" @click="$modal.show('modal-confirm-close-claim')" class="bg-red-100 hover:bg-red-200 duration-200 text-red-900 px-4 py-2 rounded text-sm font-light"><i class="fas fa-times mr-1"></i> Refuser la demande</button>
                        <button v-if="claimFlag == 0" @click="endClaim" class="bg-green-100 hover:bg-green-200 duration-200 text-green-900 px-4 py-2 rounded text-sm font-light"><i class="fas fa-check mr-1"></i> Clôturer la demande</button>
                    </template>
                    <spinner v-else :size="10" />
                </div>
                <div class="bg-green-100 p-4 rounded text-green-800 font-bold mb-5" v-if="claimFlag == 1">
                    Demande clôturée
                    <div v-if="botValidation" class="text-xs font-light">Demande validée automatiquement</div>
                    <div v-if="acceptedBy" class="text-xs font-light">Clôturée par {{ acceptedBy.email }} le {{ endDate | momentDateTime }}</div>
                </div>
                <div class="bg-red-100 p-4 rounded text-red-800 font-bold mb-5" v-if="claimFlag == 2">
                    <div>Demande refusée</div>
                    <div class="text-sm font-light"><div v-html="motifRejet"></div></div>
                    <div v-if="refusedBy" class="text-xs font-light mt-2 pt-2 border-t border-red-700">Refusée par {{ refusedBy.email }} le {{ endDate | momentDateTime }}</div>
                </div>
                <div class="bg-red-100 p-4 rounded text-red-800 font-bold mb-5" v-if="claimFlag == 3">
                    <div>Demande supprimée</div>
                    <div v-if="refusedBy" class="text-xs font-light">Supprimée par {{ refusedBy.email }} le {{ endDate | momentDateTime }}</div>
                </div>
                <div class="bg-orange-100 p-4 rounded text-orange-800 text-sm mb-5" v-if="errors && errors.length > 0 && claimFlag == 0">
                    <div class="mb-2 font-semibold">Points à vérifier : </div>
                    <ul class="ml-4 list-disc font-light">
                        <li v-for="(error, errorIndex) in errors" :key="'error-' + errorIndex">{{ error }}</li>
                        <li v-if="!claim.enseigne.id">Société: Vous devez créer la société</li>
                        <li v-if="!claim.account.id">Utilisateur: Vous devez créer l'utilisateur</li>
                    </ul>
                </div>
                <!-- Navbar -->
                <ul class="grid md:grid-cols-5 text-xs text-center divide-x divide-gray-200 rounded-lg border">
                    <li class="w-full">
                        <button @click="selectedMenu = 'enseigne'" :class="selectedMenuStyle('enseigne')" class="rounded-l-lg " aria-current="page">
                            <i v-if="!claim.enseigne.id" class="fa-solid fa-triangle-exclamation mr-1"></i>
                            <i v-else class="fa-solid fa-circle-check mr-1"></i>
                            Station
                        </button>
                    </li>
                    <li class="w-full">
                        <button @click="selectedMenu = 'account'" :class="selectedMenuStyle('account')" aria-current="page">
                            <i v-if="!claim.account.id" class="fa-solid fa-triangle-exclamation mr-1"></i>
                            <i v-else class="fa-solid fa-circle-check mr-1"></i>
                            Utilisateur
                        </button>
                    </li>
                    <li class="w-full">
                        <button @click="selectedMenu = 'informations'" :class="selectedMenuStyle('informations')" class="rounded-r-lg" aria-current="page">
                            Commercial
                        </button>
                    </li>
                </ul>

                <!-- Enseigne -->
                <div v-if="selectedMenu == 'enseigne'" class="mt-4">
                    <div v-if="!claim.enseigne.id" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                        <i class="fas fa-triangle-exclamation mr-1"></i> Vous devez créer la station
                    </div>
                    <div class="p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50" role="alert" v-if="claim.enseigne.reparations">
                        <h2 class="font-bold mb-2">Type de réparations:</h2>
                        <div class="grid grid-cols-4 gap-2">
                            <div>Brun (Télé, Hifi, Vidéo, ...): <i :class="claim.enseigne.reparations.brun ? 'fas fa-check text-green-500' : 'fas fa-times text-red-500'"></i></div>
                            <div>Blanc (Eléctroménager, ...):  <i :class="claim.enseigne.reparations.blanc ? 'fas fa-check text-green-500' : 'fas fa-times text-red-500'"></i></div>
                            <div>Gris (Informatique, téléphonie, ...):  <i :class="claim.enseigne.reparations.gris ? 'fas fa-check text-green-500' : 'fas fa-times text-red-500'"></i></div>
                            <div>Vert (Thermique, ...): <i :class="claim.enseigne.reparations.vert ? 'fas fa-check text-green-500' : 'fas fa-times text-red-500'"></i></div>
                            <div>Electroportatif: <i :class="claim.enseigne.reparations.electroportatif ? 'fas fa-check text-green-500' : 'fas fa-times text-red-500'"></i></div>
                            <div>Mobilité (Vélo, trotinette, ...): <i :class="claim.enseigne.reparations.mobilite ? 'fas fa-check text-green-500' : 'fas fa-times text-red-500'"></i></div>
                        </div>
                    </div>
                    <reparateur-view
                    @update="reparateurUpdate"
                    :edit="true"
                    :id="claim.enseigne.id"
                    :data-src="!claim.enseigne.exist ? {
                        siret: claim.enseigne.siret,
                        tva_sta: claim.enseigne.tva,
                        nom: claim.enseigne.nom,
                        siret: claim.enseigne.siret,
                        adresse: claim.enseigne.adresse.adresse1,
                        adresse2: claim.enseigne.adresse.adresse2,
                        cp: claim.enseigne.adresse.cp,
                        ville: claim.enseigne.adresse.ville,
                        activites: claim.enseigne.activites
                    } : null"
                    />
                </div>

                <!-- Account -->
                <div v-if="selectedMenu == 'account'" class="mt-4">
                    <div v-if="!claim.enseigne.id" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                        <i class="fas fa-triangle-exclamation mr-1"></i> La création de la station est obligatoire pour créer un compte
                    </div>
                    <div v-if="!claim.account.id && claim.enseigne.id" class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50" role="alert">
                        <i class="fas fa-triangle-exclamation mr-1"></i> Vous devez créer l'utilisateur
                    </div>
                    <account-view
                    v-if="claim.enseigne.id"
                    :edit="true"
                    :id="claim.account.id"
                    @update="accountUpdate"
                    :data-src="!claim.account.id ? {
                        id_entite: claim.enseigne.id,
                        type_entite: 5,
                        email: claim.account.email,
                        contact_mail: claim.account.email,
                        contact_mobile: claim.account.tel,
                        user_name: claim.account.nom,
                        prenom_user: claim.account.prenom,
                        poste: claim.account.poste,
                        user_lang: (claim.lang || 'FR').toUpperCase()
                    } : null"
                    />
                </div>

                <!-- informations -->
                <div v-show="selectedMenu == 'informations'" class="mt-4">
                    <add-dolibarr-thier
                    :claim-id="dataSrc.id"
                    :dolibarr-id="dataSrc.dolibarr_id"
                    :societe="claim.enseigne.nom"
                    :adresse="claim.enseigne.adresse.adresse1"
                    :cp="claim.enseigne.adresse.cp"
                    :ville="claim.enseigne.adresse.ville"
                    :pays="claim.enseigne.adresse.pays"
                    :tel="claim.account.tel"
                    :nom="claim.account.nom"
                    :prenom="claim.account.prenom"
                    :poste="claim.account.poste"
                    :mail="claim.account.email"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import AccountView from '../../../Magasin/AccountView.vue'
import ReparateurView from '../../../Reparateur/ReparateurView.vue'
import AddDolibarrThier from './AddDolibarrThiers.vue'
import TchatComplement from '../TchatComplement.vue'
import SavedComplementMsgs from '../SavedComplementMsgs.vue'

export default {
    props: {
        dataSrc: {required: true}
    },

    components: {
        AccountView,
        ReparateurView,
        AddDolibarrThier,
        TchatComplement,
        SavedComplementMsgs
    },

    watch: {
        dataSrc: {
            deep: true,
            handler: function() {
                this.initClaim()
            }
        }
    },

    data() {
        return {
            displayPage: false,

            claimsList: [],
            claim: null,
            claimId: null,
            claimFlag: null,
            selectedClaimType: 0,
            acceptedBy: null,
            refusedBy: null,
            endDate: null,
            botValidation: false,

            displayComplement: false,

            errors: [],

            informations: '',
            noteTimer: null,
            motifRejet: null,

            selectedMenu: 'enseigne',
            selectedLine: null,

            mustValidLiaison: false,

            loaderGetData: false,
            loaderCloseClaim: false,
            loadingCloseClaim: false,
        }
    },

    filters: {
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },

    computed: {
        typeIdent() {
            switch (this.claim.type) {
                case 'fournisseur':
                    return 3
            }
            return null
        },
    },

    methods: {
        initClaim() {
            this.displayPage = false

            this.selectedMenu = 'enseigne'
            this.claim = this.dataSrc.claim
            this.informations = this.dataSrc.informations
            this.claimId = this.dataSrc.id
            this.claimFlag = this.dataSrc.flag
            this.motifRejet = this.dataSrc.motif_rejet
            this.errors = this.dataSrc.errors
            this.acceptedBy = this.dataSrc.accepted_by
            this.refusedBy = this.dataSrc.refused_by
            this.endDate = this.dataSrc.end_date
            this.botValidation = this.dataSrc.bot_validation
            // this.selectNextMenu()

            this.$nextTick(() => {
                this.displayPage = true
            })
        },
    
        async editClaim() {
            let endObj = {
                line_id: this.claimId,
                json: JSON.stringify(this.claim),
            }
            if (this.informations) {
                endObj.informations = this.informations
            }
            let response = await this.$request.post('inscription.edit-claim', endObj)
            this.errors = JSON.parse(response.data.posts.post.errors)
            if (response.data.posts.post.flag != this.claimFlag) {
                this.$parent.$parent.getClaim(this.claimsList[0].id)
            }
        },

        selectNextMenu() {
            if (this.claim) {
                if (!this.claim.account.id) {
                    this.selectedMenu = 'account'
                }
                if (!this.claim.enseigne.exist) {
                    this.selectedMenu = 'enseigne'
                }
            }
        },

        async reparateurUpdate(data) {
            this.claim.enseigne.exist = true
            this.claim.enseigne.id = data.id
            this.claim.enseigne.nom = data.nom
            this.claim.enseigne.siret = data.siret
            this.claim.enseigne.tva = data.tva_sta
            this.claim.enseigne.adresse.adresse1 = data.adresse
            this.claim.enseigne.adresse.adresse2 = data.adresse2
            this.claim.enseigne.adresse.cp = data.cp
            this.claim.enseigne.adresse.pays = data.pays
            this.claim.enseigne.adresse.ville = data.ville
            this.$nextTick(() => {
                this.editClaim()
                this.selectNextMenu()
            })
        },

        async accountUpdate(data) {
            this.claim.account.exist = true
            this.claim.account.id = data.id
            this.claim.account.email = data.email
            this.claim.account.tel = data.contact_mobile
            this.claim.account.poste = data.poste
            this.$nextTick(() => {
                this.editClaim()
                this.selectNextMenu()
            })
        },

        selectedMenuStyle(type) {
            let style = 'inline-block w-full p-3 focus:outline-none'

            if (this.selectedMenu == type) {
                style += '  font-medium'
                if ((this.selectedMenu == 'account' && !this.claim.account.id) || (this.selectedMenu == 'enseigne' && !this.claim.enseigne.id)) {
                    style += ' bg-orange-400 text-white'
                } else {
                    style += ' bg-slate-200'
                }
            } else {
                style += ' font-light hover:bg-gray-50'
                if ((type == 'account' && !this.claim.account.id) || (type == 'enseigne' && !this.claim.enseigne.id)) {
                    style += ' text-orange-600'
                } else {
                    style += ' text-slate-900'
                }
            }
            return style
        },

        async endClaim() {
            this.loadingCloseClaim = true
            let response = await this.$request.post('inscription.end-claim', {
                line_id: this.claimId
            })
            this.loadingCloseClaim = false
            if (response.data.posts.post) {
                this.claimFlag = 1
                this.$eventHub.$emit('refresh-claim-list')
            }
        },

        async closeClaim() {
            if (!this.$validation.check([
                {value: this.motifRejet, rules: 'required', id: 'inp-rejet'},
            ])) {return}

            await this.$request.post('inscription.delete', {
                line_id: this.claimId,
                motif: this.motifRejet
            })
            this.claimFlag = 2
            this.$eventHub.$emit('refresh-claim-list')
            this.$modal.hide('modal-confirm-close-claim')
        },

        async deleteClaim() {
            await this.$request.post('inscription.delete', {
                line_id: this.claimId,
            })
            this.claimFlag = 3
            this.$eventHub.$emit('refresh-claim-list')
            this.$modal.hide('modal-confirm-delete-claim')
        },
    },

    mounted () {
        this.initClaim()
    },
}
</script>

<style lang="scss" scoped>

</style>
