<template>
    <div>
        <div class="bg-blue-200 text-slate-800 p-4 rounded">
            <div class="border-b pb-2 border-blue-300 flex items-center justify-between">
                <div class="text-blue-700 text-sm font-bold">
                    <i class="fas fa-save mr-1"></i> Par défaut
                </div>
                <button @click="displayFormNewMsg = !displayFormNewMsg" class="px-2 py-1 text-xs bg-blue-500 text-white rounded">
                    <template v-if="!displayFormNewMsg">
                        <i class="fas fa-plus"></i>
                    </template>
                    <template v-if="displayFormNewMsg">
                        <i class="fas fa-minus"></i>
                    </template>
                </button>
            </div>
            <div v-if="displayFormNewMsg" class="bg-blue-400 p-3 rounded mt-2">
                <input-rule v-model="newMsg" type="textarea" :options="{rows: 2}" placeholder="Nouveau message à enregistrer" :label-inline="false" id="inp-new-msg" />
                <button class="bg-blue-600 hover:bg-blue-700 text-white duration-200 px-4 py-2 mt-2 text-xs rounded" @click="addMsg"><i class="fas fa-check mr-1"></i> Enregistrer</button>
            </div>
            <div>
                <div @click="selectMsg(msg.message)" class="border-b border-blue-300 text-blue-800 hover:font-bold duration-200 cursor-pointer p-2 text-sm" v-for="(msg, msgIndex) in msgs" :key="'msg-' + msgIndex">
                    <div class="flex items-center justify-between">
                        <div class="flex items-center gap-2">
                            <i class="fas fa-comment"></i>
                            <div v-html="msg.message"></div>
                        </div>
                        <button @click.stop="deleteMsg(msg)">
                            <i class="fas fa-trash text-red-700 text-xs"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {},
        from: {}
    },
    data() {
        return {
            newMsg: null,
            msgs: [],
            displayFormNewMsg: false
        }
    },
    methods: {
        async getMsgs() {
            this.msgs = await this.$request.send('inscription.get-default-msg', {
                type: this.type,
                from: this.from
            })
        },
        async addMsg() {
            if (!this.$validation.check([
                {value: this.newMsg, rules: 'required', id: 'inp-new-msg'},
            ])) {return}

            let message = this.newMsg.replace(/\r?\n/g, '<br />');

            await this.$request.post('inscription.add-default-msg', {
                type: this.type,
                message: message,
                from: this.from
            })

            this.displayFormNewMsg = false

            this.newMsg = null
            this.getMsgs()
        },
        selectMsg(msg) {
            if (this.from == 'complement') {
                let message = msg.split("<br/>").join("\n")
                message = message.split("<br />").join("\n")
                this.$eventHub.$emit('fill-tchat-msg', message)
            } else {
                this.$emit('select', msg)
            }
        },
        async deleteMsg(msg) {
            await this.$request.post('inscription.delete-default-msg', {
                line_id: msg.id
            })
            this.getMsgs()
        }
    },
    mounted () {
        this.getMsgs()
    },
}
</script>

<style lang="scss" scoped>

</style>