import { render, staticRenderFns } from "./AdminClientLiaisonsTable.vue?vue&type=template&id=a39176b0&scoped=true"
import script from "./AdminClientLiaisonsTable.vue?vue&type=script&lang=js"
export * from "./AdminClientLiaisonsTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a39176b0",
  null
  
)

export default component.exports