<template>
    <div>
        <modal :name="'modal-add-liaison-' + _uid" height="350px" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        Ajouter une liaison
                    </h2>
                    <button @click="$modal.hide('modal-add-liaison-' + _uid)">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                
                <div class="mb-8">
                    <div class="font-bold text-lg">Centrale Achat</div>
                    <input-rule
                    id="select-enseigne"
                    v-model="newLiaison"
                    :label-inline="false"
                    placeholder="Centrale Achat"
                    type="api-select"
                    :options="{
                        api: {action: 'inscription.search-centrale', apiName: 'api2'},
                        label: 'centrale_ha',
                        init: false,
                        min: 3,
                        uppercase: true
                    }"
                    />
                </div>

                <div class="my-4" v-if="newLiaison">
                    <div class="font-bold text-lg">Notification par email</div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="flex items-center gap-2">
                            <input-rule v-model="notifEmailCentrale" type="pcheck" :label-inline="false" /> <span class="text-sm">{{ 'Envoyer un email à ' + newLiaison.centrale_ha }}</span>
                        </div>
                        <div class="flex items-center gap-2">
                            <input-rule  v-model="notifEmailFournisseur" type="pcheck" :label-inline="false" /> <span class="text-sm">{{ 'Envoyer un email à ' + nom }}</span>
                        </div>
                    </div>
                </div>

                <div class="border-t pt-4 mt-4">
                    <button :disabled="loaderLiaison" @click="makeLiaison(newLiaison)" class="bg-green-600 hover:bg-green-700 duration-200 cursor-pointer text-white px-4 py-2 rounded text-sm">
                        <template v-if="!loaderLiaison">
                            Valider <i class="fas fa-check ml-1"></i>
                        </template>
                        <template v-if="loaderLiaison">
                            <spinner :size="10" />
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <div v-if="loadingGetData"><spinner :size="10" /></div>
        <div v-if="!loadingGetData">
            <div
            class="mt-2 bg-white p-2 rounded border md:flex gap-2 items-center justify-between"
            v-for="(liaison, liaisonIndex) in validations"
            :key="'liaison-' + liaisonIndex"
            >
                <div v-if="liaison.id" class="text-blue-500 hover:underline cursor-pointer text-sm" @click="selectedLine = liaison; $modal.show('modal-display-centrale')">{{ liaison.nom }}</div>
                <div v-else class="text-sm">{{ liaison.nom }}</div>
                <div class="grow border-b border-dashed border-slate-300 my-2 md:my-0"></div>
                <div v-if="!liaison.id && !liaison.liaison_id">
                    <input-rule
                    id="select-enseigne"
                    v-model="selectedLiaison"
                    :label-inline="false"
                    placeholder="Enseigne"
                    type="api-select"
                    :options="{
                        api: {action: 'inscription.search-centrale', apiName: 'api2'},
                        label: 'centrale_ha',
                        init: false,
                        min: 3,
                        uppercase: true
                    }"
                    />
                </div>
                <div class="flex gap-1">
                    <template v-if="!liaison.liaison_id && !liaison.cancelled">
                        <button @click="makeLiaison(liaison)" class="bg-green-400 duration-200 hover:bg-green-500 px-2 py-1 rounded text-sm text-white"><i class="fas fa-check"></i></button>
                        <button @click="cancelLiaison(liaison)" class="bg-red-400 duration-200 hover:bg-red-500 px-3 rounded text-sm text-white"><i class="fas fa-times"></i></button>
                    </template>
                    <div class="bg-green-200 text-xs font-light px-4 py-1 rounded" v-if="liaison.liaison_id">
                        <template v-if="liaison.found && liaison.found == 1">Déjà existante</template>
                        <template v-else>Validée</template>
                    </div>
                    <div class="bg-red-200 text-xs font-light px-4 py-1 rounded" v-if="liaison.cancelled">
                        Refusée
                    </div>
                </div>
            </div>
            <div class="text-right mt-4 mb-2">
                <button @click="$modal.show('modal-add-liaison-' + _uid)" class="px-4 py-2 bg-slate-500 hover:bg-slate-600 duration-200 text-xs rounded text-white"><i class="fas fa-plus mr-0.5"></i> Ajouter une liaison</button>
            </div>
            <admin-client-liaisons-table
            class="mt-4"
            type="fournisseur"
            :connected="1"
            :entite-id="id"
            />
        </div>
    </div>
</template>

<script>
import AdminClientLiaisonsTable from '../../AdminClient/Components/AdminClientLiaisonsTable.vue'

export default {
    components: {
        AdminClientLiaisonsTable,
    },

    props: {
        id: {required: true},
        nom: {required: true},
        validations: {},
    },
    watch: {
        id: function() {
            this.$nextTick(() => {
                this.$eventHub.$emit('refresh-lines', 'table-liaisons')
            })
        }
    },
    data() {
        return {
            selectedLiaison: null,
            loadingGetData: false,
            newLiaison: null,
            loaderLiaison: false,
            notifEmailCentrale: false,
            notifEmailFournisseur: false,
        }
    },
    methods: {
        async makeLiaison(liaison) {
            if (!liaison.id && !this.$validation.check([
                {value: this.selectedLiaison, rules: 'required', id: 'select-enseigne'},
            ])) {return}
            this.loaderLiaison = true
            let liaisonObj = {
                id_fournisseur: this.id,
                id_centrale_ha: liaison.id ? liaison.id : this.selectedLiaison.id,
                from: 'fournisseur',
                notif_current: this.notifEmailFournisseur ? 1 : 0,
                notif_new: this.notifEmailCentrale ? 1 : 0,
            }
            if (liaison.code_compta_ext) {
                liaisonObj.code_compta_ext = liaison.code_compta_ext
            }
            let response = await this.$request.post('inscription.make-liaison', liaisonObj)
            this.loaderLiaison = false
            this.$toast.success('Liaison enregistrée', {position: "bottom-right"})
            if (this.selectedLiaison) {
                liaison.nom = this.selectedLiaison.nom
            }
            this.$nextTick(() => {
                this.$eventHub.$emit('refresh-lines', 'table-liaisons')
            })
            if (!this.newLiaison) {
                this.$emit('update', response.data.posts.post)
            } else {
                this.$modal.hide('modal-add-liaison-' + this._uid)
                this.newLiaison = null
            }
        },

        cancelLiaison(liaison) {
            this.$emit('cancel', liaison)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$eventHub.$emit('refresh-lines', 'table-liaisons')
        })
    }
}
</script>

<style lang="scss" scoped>

</style>
