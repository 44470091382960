import { render, staticRenderFns } from "./FacturationEditClientOptions.vue?vue&type=template&id=1db58d73&scoped=true"
import script from "./FacturationEditClientOptions.vue?vue&type=script&lang=js"
export * from "./FacturationEditClientOptions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1db58d73",
  null
  
)

export default component.exports