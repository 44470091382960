import { render, staticRenderFns } from "./AddDolibarrThiers.vue?vue&type=template&id=64111b24&scoped=true"
import script from "./AddDolibarrThiers.vue?vue&type=script&lang=js"
export * from "./AddDolibarrThiers.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64111b24",
  null
  
)

export default component.exports