<template>
    <div>
        <div class="fixed top-0 left-0 right-0 flex items-center justify-between px-[15px] py-2 mb-5 bg-gray-100" style="border-bottom: 1px solid #e6e6e6">
            <a href="https://districloud.net"><img src="https://disticloudfiles.net/upload/files/logo_districloud.png" class="h-[39px]" /></a>
            <!-- <div class="flex items-center justify-between gap-4">
                <button @click="changeLang('fr')"><img :class="this.lang == 'fr' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Flag_of_France.png/1280px-Flag_of_France.png" alt=""></button>
                <button @click="changeLang('en')"><img :class="this.lang == 'en' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Flag_of_the_United_Kingdom.png/1200px-Flag_of_the_United_Kingdom.png" alt=""></button>
                <button @click="changeLang('de')"><img :class="this.lang == 'de' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/1280px-Flag_of_Germany.svg.png" alt=""></button>
                <button @click="changeLang('es')"><img :class="this.lang == 'es' ? 'border border-gray-500 h-4' : 'opacity-30 hover:opacity-100 duration-200 h-2.5'" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/2560px-Flag_of_Spain.svg.png" alt=""></button>
            </div> -->
        </div>

        <div class="container mx-auto xl:w-2/3 mt-16">
            <div class="p-4">
                <spinner v-if="loadingGetData" />
            </div>
            <div v-if="!loadingGetData && claim">
                <div class="my-10">
                    <div v-if="claim" class="grid md:grid-cols-5 md:gap-2">
                        <div class="p-4 pt-0 pl-0 text-sm col-span-2">
                            <div class="border-b mb-4 pb-4">
                                <div class="bg-orange-100 p-4 rounded mb-2">
                                    <h1 class="text-2xl font-bold mb-2">Demande de complément concernant votre inscription à la plateforme SAV Districloud</h1>
                                    <div class="font-light"><i class="fas fa-calendar-days mr-1 mb-2"></i> Demande du : {{ claim.date_dem | momentDateTime }}</div>
                                    <div class="font-light"><i class="fas fa-exclamation-triangle mr-1"></i> Votre demande d'inscription ne peut pas être validée pour le moment. Merci de répondre au message ci-contre.</div>
                                </div>
                            </div>

                            <template v-if="claim.type_ident == 'magasin'">
                                <div class="border-b pb-4 mb-4">
                                    <div class="font-bold">Magasin :</div>
                                    <div class="ml-4 mt-2">
                                        <div class="mb-0.5">NOM: {{ claim.meta.magasin.nom }}</div>
                                        <div class="mb-0.5">SIRET: {{ claim.meta.magasin.siret }}</div>
                                        <div class="mb-0.5">TVA: {{ claim.meta.magasin.tva }}</div>
                                        <div class="mb-0.5">ADRESSE: {{ claim.meta.magasin.adresse.adresse1 + ' ' + claim.meta.magasin.adresse.adresse2 + ', ' + claim.meta.magasin.adresse.cp + ' ' + claim.meta.magasin.adresse.ville + ' ' + claim.meta.magasin.adresse.pays }}</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="font-bold mt-4">Utilisateur :</div>
                                    <div class="ml-4 mt-2">
                                        <div class="mb-0.5">PRENOM: {{ claim.meta.account.prenom }}</div>
                                        <div class="mb-0.5">NOM: {{ claim.meta.account.nom }}</div>
                                        <div class="mb-0.5">TEL: {{ claim.meta.account.tel }}</div>
                                        <div class="mb-0.5">EMAIL: {{ claim.meta.account.email }}</div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="claim.type_ident == 'fournisseur'">
                                <div class="border-b pb-4 mb-4">
                                    <div class="font-bold">Enseigne :</div>
                                    <div class="ml-4 mt-2">
                                        <div class="mb-0.5">NOM: {{ claim.meta.enseigne.nom }}</div>
                                        <div class="mb-0.5">SIRET: {{ claim.meta.enseigne.siret }}</div>
                                        <div class="mb-0.5">TVA: {{ claim.meta.enseigne.tva }}</div>
                                        <div class="mb-0.5">ADRESSE: {{ claim.meta.enseigne.adresse.adresse1 + ' ' + claim.meta.enseigne.adresse.adresse2 + ', ' + claim.meta.enseigne.adresse.cp + ' ' + claim.meta.enseigne.adresse.ville + ' ' + claim.meta.enseigne.adresse.pays }}</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="font-bold mt-4">Utilisateur :</div>
                                    <div class="ml-4 mt-2">
                                        <div class="mb-0.5">PRENOM: {{ claim.meta.account.prenom }}</div>
                                        <div class="mb-0.5">NOM: {{ claim.meta.account.nom }}</div>
                                        <div class="mb-0.5">TEL: {{ claim.meta.account.tel }}</div>
                                        <div class="mb-0.5">EMAIL: {{ claim.meta.account.email }}</div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="p-4 col-span-3 border rounded">
                            <h2 class="text-xl font-bold border-b pb-2 mb-4">Conversation</h2>
                            <tchat-complement :claim-id="claim.id" :admin="false" />
                        </div>
                    </div>
                </div>


                <div class="fixed bottom-0 left-0 right-0 flex items-center justify-between px-[15px] py-2 bg-gray-100">
                    <img src="https://www.districloud.net/img/logosavlog.png" class="h-[29px]" />
                    <p class="text-sky-500" style="text-align: right; font-size: 10px; margin-right: 10px;" id="copyright">
                        <a href="https://www.districloud.fr" target="_blank"> www.districloud.fr</a>  © Savlog, DistriCloud 1998 - 2023 v2.2.1
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TchatComplement from './Components/TchatComplement.vue'
import moment from 'moment'

export default {
    components: {
        TchatComplement,
    },
    data() {
        return {
            loadingGetData: false,
            claim: null,
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getClaim() {
            let token = this.$route.query.token
            if (!token) {
                window.location = '/'
            }
            this.loadingGetData = true
            let response = await this.$request.post('inscription.get-claim-token', {
                claim_token: token,
            })
            if (response.data.posts.post == 'invalid_token') {
                window.location = '/'
            } else {
                this.claim = response.data.posts.post
                this.claim.meta = JSON.parse(this.claim.meta)
            }
            this.loadingGetData = false
        }
    },
    mounted () {
        this.getClaim();
    },
}
</script>

<style lang="scss" scoped>

</style>