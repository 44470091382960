import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

/* --------------
    PACKAGES
---------------*/

// Toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  timeout: 2500,
  hideProgressBar: true,
});

// Modal
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal)

// Checkbox
import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox)

// Multiselect
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

// Vue select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

// DropDown
import DropdownMenu from 'v-dropdown-menu'
import 'v-dropdown-menu/dist/v-dropdown-menu.css'
Vue.use(DropdownMenu)

// Input tel
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// Table
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
Vue.use(VueEasytable);

// Vue tooltip
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

/* --------------
    TOOLS
---------------*/

import CryptoTool from './tools/CryptoTool'
Vue.prototype.$crypto = CryptoTool

import ValidationTool from './tools/ValidationTool'
Vue.prototype.$validation = ValidationTool

import PermissionTool from './tools/PermissionTool'
Vue.prototype.$permissionTool = PermissionTool

import LangTextTool from './tools/LangTextTool'
Vue.prototype.$tt = LangTextTool.getText

import LangTool from './tools/LangTool'
Vue.prototype.$lang = LangTool

import addressTool from './tools/AddressTool'
Vue.prototype.$address = addressTool

import EventHub from '@/eventHub';
Vue.prototype.$eventHub = EventHub

import apiService from './services/api.service'
Vue.prototype.$request = apiService


/* --------------
    COMPONENTS
---------------*/

import Spinner from '@/components/Spinner.vue'
Vue.component('spinner', Spinner)

import AddressInput from '@/components/AddressInput.vue'
Vue.component('address-input', AddressInput)

import AddressInput2 from '@/components/AddressInput2.vue'
Vue.component('address-input2', AddressInput2)

import InputRule from '@/components/InputRule.vue'
Vue.component('input-rule', InputRule)

import InputFile from '@/components/InputFile.vue'
Vue.component('input-file', InputFile)

import AppTemplate from '@/components/AppTemplate.vue'
Vue.component('app-template', AppTemplate)

import DataTable from '@/components/DataTable.vue'
Vue.component('data-table', DataTable)

import CardTitle from '@/components/CardTitle.vue'
Vue.component('card-title', CardTitle)

import SmartSelect from '@/components/SmartSelect'
Vue.component('smart-select', SmartSelect)

import ApiSelect from '@/components/ApiSelect'
Vue.component('api-select', ApiSelect)

import TelInput from '@/components/TelInput'
Vue.component('tel-input', TelInput)

import ToggleCard from '@/components/ToggleCard'
Vue.component('toggle-card', ToggleCard)

import DisplayValue from '@/components/DisplayValue'
Vue.component('display-value', DisplayValue)

import PaginateMenuItem from '@/components/PaginateMenuItem'
Vue.component('paginate-menu-item', PaginateMenuItem)

import BtnConfirmDelete from '@/components/BtnConfirmDelete'
Vue.component('btn-confirm-delete', BtnConfirmDelete)

import Tooltip from '@/components/Tooltip'
Vue.component('tooltip', Tooltip)

/* --------------
    APP
---------------*/

let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName]
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },

  unbind () {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
})


// Router
import router from './router/index.js'

import ApiService from '@/services/api.service';
async function initApp() {
    // Get lang
    await LangTextTool.init()

    // Get Permissions
    if (localStorage['account']) {
      let response = await ApiService.post('account.permissions-list')
      let permissions = response.data.posts.post
      if (permissions) {
          localStorage.setItem('permissions', JSON.stringify(permissions))
      } else {
          localStorage.removeItem('permissions')
      }
    }

    // Create app
    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
}

initApp()
